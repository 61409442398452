import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($ID: String!) {
    allContentfulPainting(filter: { id: { eq: $ID } }) {
      nodes {
        image {
          file {
            url
            fileName
          }
        }
        name
        description {
          description
        }
        year
      }
    }
    allContentfulPrint(filter: { id: { eq: $ID } }) {
      nodes {
        image {
          file {
            url
            fileName
          }
        }
        name
        description {
          description
        }
        price
        year
      }
    }
  }
`

export default ({ data }) => {
  const imageData =
    data.allContentfulPainting.nodes.length !== 0
      ? data.allContentfulPainting.nodes[0]
      : data.allContentfulPrint.nodes.length !== 0
      ? data.allContentfulPrint.nodes[0]
      : []

  return (
    <Layout>
      {imageData !== [] ? (
        <div className={"row"}>
          <SEO title={imageData.name} keywords={[`Målning`, `Grafik`]} />
          <div className={"six columns"}>
            <img style={{ width: "100%" }} src={imageData.image.file.url} />
          </div>
          <div className={"six columns"}>
            <h2
              style={{
                fontWeight: 900,
                fontSize: "2.0em",
                textTransform: "uppercase",
              }}
            >
              {imageData.name}
            </h2>
            {imageData.description.description.split("\n").map((item, i) => {
              return <p key={i}>{item}</p>
            })}
            {imageData.price ? (
              <p>
                <span style={{ fontWeight: "900" }}>Pris</span>:{" "}
                {imageData.price}kr
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </Layout>
  )
}
